import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import Pagination from "../components/Blog/Pagination";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";
import BlogPage from "../components/Blog/BlogPage";

const BlogPages = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const tags = data.tagsGroup.distinct;
  const { meta, social } = useSiteMetadata();

  return (
    <Layout darkMenu={true}>
      <Seo
        title={
          meta.blogName +
          (pageContext.pageNumber > 0
            ? meta.sitePageTitleSep +
              "Page " +
              (pageContext.pageNumber + 1) +
              " of " +
              pageContext.numberOfPages
            : "") +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={
          meta.siteUrl +
          "/blog/" +
          (pageContext.pageNumber ? "page/" + (pageContext.pageNumber + 1) : "")
        }
        description={meta.blogDescription}
        image={meta.siteUrl + meta.siteImage}
        twitterTitle={meta.siteTitle + " " + meta.blogName}
        facebookTitle={meta.siteTitle + " " + meta.blogName}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />
      <BlogPage
        title={[meta.blogName]}
        blogDescription={meta.blogDescription}
        categoryLinkText={"Categories"}
        posts={posts}
        tags={tags}
        pageId={"blog-page" + pageContext.pageNumber}
      />

      <Pagination pageContext={pageContext} pathPrefix="/journal" />
    </Layout>
  );
};

export default BlogPages;

export const blogPagesQuery = graphql`
  query blogPagesQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "//blog//" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            path
            date(formatString: "MMMM DD, YYYY")
            excerpt
            tags
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    tagsGroup: allMarkdownRemark {
      distinct(field: frontmatter___tags)
    }
  }
`;
